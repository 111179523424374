import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import type { TimeRange } from '../types';
import { TIME_RANGES } from '../types';

interface TimeRangeSelectorProps {
  value: TimeRange;
  onChange: (value: TimeRange) => void;
}

export default function TimeRangeSelector({ value, onChange }: TimeRangeSelectorProps) {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(_, newValue) => newValue && onChange(newValue)}
      size="small"
      sx={{ mb: 2 }}
    >
      {Object.entries(TIME_RANGES).map(([key, { label }]) => (
        <ToggleButton key={key} value={key}>
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
