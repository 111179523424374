import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface ErrorMessageProps {
  error: Error | unknown;
  title?: string;
}

export default function ErrorMessage({ error, title = 'Error' }: ErrorMessageProps) {
  const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="200px"
      p={3}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Alert severity="error" sx={{ maxWidth: '600px', width: '100%' }}>
        {errorMessage}
      </Alert>
    </Box>
  );
}
