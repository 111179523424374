import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { callApi } from '../../../utils/api';

interface LoggedInPasswordResetProps {
  email: string;
}

export default function LoggedInPasswordReset({ email }: LoggedInPasswordResetProps) {
  const theme = useTheme();
  const [error, setError] = React.useState('');
  const [emailSent, setEmailSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    setError('');
    event.preventDefault();

    try {
      const response = await callApi('/users/reset-password/request', {
        method: 'POST',
        body: JSON.stringify({ email }),
      });
      const data = (await response.json()) as { message?: string; error?: string };

      if (response.ok) {
        setEmailSent(true);
      } else {
        setError(data.error || data.message || 'An error occurred while requesting password reset');
      }
    } catch (err) {
      console.error('Error during password reset request:', err);
      setError('An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>

        {emailSent ? (
          <Box mt={2}>
            <Typography>Password reset instructions have been sent to your email.</Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate('/')}
              sx={{ mt: 2 }}
            >
              Return to Dashboard
            </Button>
          </Box>
        ) : (
          <Box component="form" onSubmit={handleSubmit} mt={2}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Click below to receive password reset instructions at:
            </Typography>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                mb: 2,
                backgroundColor: theme.palette.grey[50],
                border: `1px solid ${theme.palette.grey[200]}`,
                borderRadius: 1,
                textAlign: 'center',
              }}
            >
              <Typography
                variant="subtitle1"
                color="error"
                fontWeight="medium"
                sx={{ wordBreak: 'break-all' }}
              >
                {email}
              </Typography>
            </Paper>

            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}

            <Button
              type="submit"
              disabled={loading}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 2 }}
            >
              Send Reset Instructions
            </Button>

            <Button
              fullWidth
              variant="text"
              onClick={() => navigate('/')}
              sx={{ textTransform: 'none' }}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Paper>
    </Container>
  );
}
