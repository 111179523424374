import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultHttpTarget } from '@cloud-ui/components/providers/ProviderEditor';
import { ROUTES } from '@cloud-ui/constants';
import { useConfig } from '@cloud-ui/contexts/ConfigContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { useStartJob } from '@cloud-ui/hooks/useJobs';
import {
  listRedteamConfigs,
  saveRedteamConfig,
  deleteRedteamConfig,
} from '@cloud-ui/utils/api/redteam';
import {
  Add as AddIcon,
  ContentCopy,
  Delete,
  Edit,
  PlayArrow,
  UploadFile as UploadFileIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  Grid,
  Paper,
} from '@mui/material';
import { type RedteamConfig } from '@shared/dto/redteamConfigs';
import { format } from 'date-fns';
import yaml from 'js-yaml';
import { getUnifiedConfig } from './edit/utils/yamlHelpers';

export default function RedteamConfigsListPage() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const { mutate: startJob } = useStartJob();
  const { enableServerSideJobs } = useConfig();

  // Loading all of the configs
  const { data: configs, isLoading } = useQuery({
    queryKey: ['redteamConfigs'],
    queryFn: () => listRedteamConfigs(),
    staleTime: 0,
    refetchInterval: 0,
  });

  const deleteConfigMutation = useMutation({
    mutationFn: (configId: string) => deleteRedteamConfig(configId),
    onSuccess: async (_, configId) => {
      queryClient.setQueryData(['redteamConfigs'], (old: RedteamConfig[] | undefined) =>
        old?.filter((config) => config.id !== configId),
      );
      await queryClient.invalidateQueries({
        queryKey: ['redteamConfigs'],
      });
      showToast('Config deleted successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to delete config:', error);
      showToast('Failed to delete config', 'error');
    },
  });

  const handleCopy = async (configId: string) => {
    const config = configs?.find((c) => c.id === configId);
    if (!config) {
      showToast(`Cannot copy config, please refresh the page`, 'error');
      return;
    }
    const copy = { ...config.config };
    copy.description = copy.description + ' (copy)';
    const result = await saveRedteamConfig(copy);
    await configs?.unshift(result);
    await queryClient.invalidateQueries({
      queryKey: ['redteamConfigs'],
    });
    showToast('Config copied successfully', 'success');
  };

  const handleDelete = async (configId: string) => {
    deleteConfigMutation.mutate(configId);
  };

  const readFileAsText = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }

      const content = await readFileAsText(file);
      const yamlConfig = yaml.load(content) as any;

      // Map the YAML structure to our expected Config format
      const mappedConfig: RedteamConfig = {
        description: yamlConfig.description || 'My Red Team Configuration',
        prompts: yamlConfig.prompts || ['{{prompt}}'],
        target: yamlConfig.targets?.[0] || yamlConfig.providers?.[0] || defaultHttpTarget(),
        plugins: yamlConfig.redteam?.plugins || ['default'],
        strategies: yamlConfig.redteam?.strategies || [],
        purpose: yamlConfig.redteam?.purpose || '',
        entities: yamlConfig.redteam?.entities || [],
        applicationDefinition: {
          purpose: yamlConfig.redteam?.purpose || '',
          // We could potentially parse these from redteam.purpose if it follows a specific format.
          redteamUser: '',
          accessToData: '',
          forbiddenData: '',
          accessToActions: '',
          forbiddenActions: '',
          connectedSystems: '',
        },
      };

      const result = await saveRedteamConfig({
        ...mappedConfig,
        name: yamlConfig.description || `Uploaded ${new Date().toLocaleDateString()}`,
      });
      showToast('Imported succesfully', 'success');
      navigate(`${ROUTES.redteam.configs}/${result.id}`);
    } catch (e) {
      console.error(`Error uploading file ${e}`);
      showToast(`Error importing Yaml config: ${e}`, 'error');
    }
  };

  const handleRun = (configId: string) => {
    const config = configs?.find((c) => c.id === configId)?.config;
    if (config) {
      startJob({ config, configId, getUnifiedConfig });
    } else {
      showToast('Configuration not found', 'error');
    }
  };

  const [isNewConfigDialogOpen, setIsNewConfigDialogOpen] = useState(false);

  const NewConfigDialog = () => (
    <Dialog
      open={isNewConfigDialogOpen}
      onClose={() => setIsNewConfigDialogOpen(false)}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 2 },
      }}
    >
      <Box sx={{ p: 4 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 500 }}>
          Create New Configuration
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={2}
              sx={{
                p: 4,
                height: '100%',
                minHeight: 240,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 2,
                },
              }}
              onClick={() => {
                setIsNewConfigDialogOpen(false);
                navigate(`${ROUTES.redteam.configs}/new`);
              }}
            >
              <Box
                sx={{
                  mb: 2.5,
                  p: 1.5,
                  borderRadius: '50%',
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                }}
              >
                <AddIcon sx={{ fontSize: 32 }} />
              </Box>
              <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
                New Config
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
                Start from scratch
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={2}
              sx={{
                p: 4,
                height: '100%',
                minHeight: 240,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 4,
                },
              }}
              onClick={() => {
                setIsNewConfigDialogOpen(false);
                navigate(`${ROUTES.redteam.configs}/example`);
              }}
            >
              <Box
                sx={{
                  mb: 2.5,
                  p: 1.5,
                  borderRadius: '50%',
                  backgroundColor: 'secondary.main',
                  color: 'secondary.contrastText',
                }}
              >
                <ContentCopy sx={{ fontSize: 32 }} />
              </Box>
              <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
                Example
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
                Try our example
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              component="label"
              htmlFor="yaml-file-upload"
              elevation={2}
              sx={{
                p: 4,
                height: '100%',
                minHeight: 240,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 4,
                },
              }}
            >
              <input
                accept=".yml,.yaml"
                style={{ display: 'none' }}
                id="yaml-file-upload"
                type="file"
                onChange={(e) => {
                  handleFileUpload(e);
                  setIsNewConfigDialogOpen(false);
                }}
              />
              <Box
                sx={{
                  mb: 2.5,
                  p: 1.5,
                  borderRadius: '50%',
                  backgroundColor: 'success.main',
                  color: 'success.contrastText',
                }}
              >
                <UploadFileIcon sx={{ fontSize: 32 }} />
              </Box>
              <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
                Upload YAML
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
                Upload an existing Promptfoo configuration file
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );

  useEffect(() => {
    if (!isLoading && (!configs || configs.length === 0)) {
      setIsNewConfigDialogOpen(true);
    }
  }, [isLoading, configs]);

  return (
    <Box maxWidth="100%" mx="auto" p={3}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <Typography variant="h4">Red Team Configurations</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsNewConfigDialogOpen(true)}
        >
          New Config
        </Button>
      </div>
      <NewConfigDialog />

      <Card>
        {!isLoading && (!configs || configs.length === 0) ? (
          <Box p={4} textAlign="center">
            <Typography variant="h6" color="text.secondary" gutterBottom>
              No Configurations Yet
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={3}>
              Create your first red team configuration to get started
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setIsNewConfigDialogOpen(true)}
            >
              Create New Config
            </Button>
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Target</TableCell>
                <TableCell>Last Updated By</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {configs?.map((config) => (
                <TableRow key={config.id}>
                  <TableCell>{config.config.description}</TableCell>
                  <TableCell>{config.config.target?.label}</TableCell>
                  <TableCell>{config.user}</TableCell>
                  <TableCell>{format(new Date(config.updatedAt), 'MMM d, yyyy HH:mm')}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => navigate(`${ROUTES.redteam.configs}/${config.id}`)}
                      title="Edit"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleCopy(config.id)} title="Copy">
                      <ContentCopy />
                    </IconButton>
                    {enableServerSideJobs && (
                      <IconButton onClick={() => handleRun(config.id)} title="Run">
                        <PlayArrow />
                      </IconButton>
                    )}
                    <IconButton onClick={() => handleDelete(config.id)} title="Delete">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Card>
    </Box>
  );
}
