import { Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

const THREAT_LABELS = {
  pii: 'PII Detection',
  harm: 'Harmful Content',
  guard: 'Prompt Injection',
} as const;

interface TypeDistributionChartProps {
  data: Array<{
    type: keyof typeof THREAT_LABELS;
    total: number;
    flagged: number;
  }>;
}

export default function TypeDistributionChart({ data }: TypeDistributionChartProps) {
  const theme = useTheme();

  // Calculate safe requests for each type
  const processedData = data.map((item) => ({
    ...item,
    safe: item.total - item.flagged,
    type: THREAT_LABELS[item.type] || item.type,
  }));

  return (
    <Paper sx={{ p: 2, height: 400 }}>
      <Typography variant="h6" gutterBottom>
        Requests by Type
      </Typography>
      <ResponsiveContainer width="100%" height="90%">
        <BarChart data={processedData}>
          <defs>
            <linearGradient id="safeBarGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={theme.palette.success.main} stopOpacity={0.8} />
              <stop offset="95%" stopColor={theme.palette.success.main} stopOpacity={0.4} />
            </linearGradient>
            <linearGradient id="flaggedBarGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={theme.palette.error.main} stopOpacity={0.8} />
              <stop offset="95%" stopColor={theme.palette.error.main} stopOpacity={0.4} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="type" />
          <YAxis allowDecimals={false} />
          <Tooltip formatter={(value: number, name: string) => [value, name]} cursor={false} />
          <Legend />
          <Bar
            dataKey="safe"
            name="Safe Requests"
            fill="url(#safeBarGradient)"
            stroke={theme.palette.success.main}
          />
          <Bar
            dataKey="flagged"
            name="Flagged Requests"
            fill="url(#flaggedBarGradient)"
            stroke={theme.palette.error.main}
          />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
}
