'use client';

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { ShiftKeyProvider } from '@cloud-ui/contexts/ShiftKeyContext';
import useApiConfig from '@cloud-ui/stores/apiConfig';
import { callApi } from '@cloud-ui/utils/api';
import CircularProgress from '@mui/material/CircularProgress';
import type { SharedResults, ResultLightweightWithLabel, ResultsFile } from '@promptfoo/types';
import ResultsView from './ResultsView';
import { useStore } from './store';
import './Results.css';

export interface RecentEvalWithAuthor extends ResultLightweightWithLabel {
  author: string;
}

interface EvalOptions {
  fetchId?: string;
  preloadedData?: SharedResults;
  recentEvals?: RecentEvalWithAuthor[];
  defaultEvalId?: string;
}

export default function Eval({
  fetchId,
  recentEvals: recentEvalsProp,
  defaultEvalId: defaultEvalIdProp,
}: EvalOptions) {
  const navigate = useNavigate();
  const { apiBaseUrl } = useApiConfig();

  const {
    table,
    setTable,
    setTableFromResultsFile,
    setIsPublic,
    config,
    setConfig,
    evalId,
    setEvalId,
    setAuthor,
    setInComparisonMode,
  } = useStore();
  const [loaded, setLoaded] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [recentEvals, setRecentEvals] = React.useState<RecentEvalWithAuthor[]>(
    recentEvalsProp || [],
  );

  const fetchRecentFileEvals = async () => {
    const resp = await callApi(`/results`, { cache: 'no-store' });
    if (!resp.ok) {
      setFailed(true);
      return;
    }
    const body = (await resp.json()) as RecentEvalWithAuthor[];
    setRecentEvals(body);
    return body;
  };

  const fetchEvalById = React.useCallback(
    async (id: string) => {
      try {
        const resp = await callApi(`/results/${id}`, { cache: 'no-store' });
        const body = (await resp.json()) as ResultsFile & { isPublic: boolean };
        setTableFromResultsFile(body);
        setIsPublic(body.isPublic);
        setConfig(body.config);
        setAuthor(body.author);
        setEvalId(id);
      } catch (e) {
        if (e instanceof Error && e.message.includes('Login required')) {
          navigate(ROUTES.welcome);
          return;
        }
        throw e;
      }
    },
    [setTable, setConfig, setEvalId, setAuthor],
  );

  const handleRecentEvalSelection = async (id: string) => {
    navigate(`${ROUTES.eval}/${id}`);
  };

  const [defaultEvalId, setDefaultEvalId] = React.useState<string>(
    defaultEvalIdProp || recentEvals[0]?.evalId,
  );

  React.useEffect(() => {
    const evalId = fetchId;
    if (evalId) {
      console.log('Eval init: Fetching eval by id', { fetchId });
      const run = async () => {
        await fetchEvalById(evalId);
        setLoaded(true);
        setDefaultEvalId(evalId);
        // Load other recent eval runs
        fetchRecentFileEvals();
      };
      run();
    } else {
      console.log('Eval init: Fetching eval via recent');
      // Fetch from server
      const run = async () => {
        const evals = await fetchRecentFileEvals();
        if (evals && evals.length > 0) {
          const defaultEvalId = evals[0].evalId;
          await fetchEvalById(defaultEvalId);
        }
        setLoaded(true);
      };
      run();
    }
    setInComparisonMode(false);
  }, [
    apiBaseUrl,
    fetchId,
    setTable,
    setConfig,
    setAuthor,
    setEvalId,
    fetchEvalById,
    setDefaultEvalId,
    setInComparisonMode,
  ]);

  React.useEffect(() => {
    document.title = `${config?.description || evalId || 'Eval'} | promptfoo`;
  }, [config, evalId]);

  const handleDeleteEval = async (evalId: string) => {
    // Remove from recent evals
    setRecentEvals((prev) => prev.filter((eval_) => eval_.evalId !== evalId));
    if (recentEvals.length > 0) {
      handleRecentEvalSelection(recentEvals[0]?.evalId);
    } else {
      navigate(ROUTES.welcome);
    }
  };

  if (failed) {
    return <div className="notice">404 Eval not found</div>;
  }
  if (loaded && !table) {
    navigate(ROUTES.welcome);
    return null;
  }

  if (!loaded || !table) {
    return (
      <div className="notice">
        <div>
          <CircularProgress size={22} />
        </div>
        <div>Waiting for eval data</div>
      </div>
    );
  }

  return (
    <ShiftKeyProvider>
      <ResultsView
        defaultEvalId={defaultEvalId}
        recentEvals={recentEvals}
        onRecentEvalSelected={handleRecentEvalSelection}
        onDelete={handleDeleteEval}
      />
    </ShiftKeyProvider>
  );
}
