import { Paper, Typography, Grid } from '@mui/material';

interface LatencyStatsProps {
  latency: {
    p50: number;
    p95: number;
    p99: number;
  };
}

export default function LatencyStats({ latency }: LatencyStatsProps) {
  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Typography variant="h6" gutterBottom>
        Latency
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2" color="text.secondary">
            P50
          </Typography>
          <Typography variant="h6">{latency.p50}ms</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" color="text.secondary">
            P95
          </Typography>
          <Typography variant="h6">{latency.p95}ms</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" color="text.secondary">
            P99
          </Typography>
          <Typography variant="h6">{latency.p99}ms</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
