export interface PasswordValidationResult {
  isValid: boolean;
  error?: string;
}

// Password length requirements
export const MIN_PASSWORD_LENGTH = 12;
export const MAX_PASSWORD_LENGTH = 64;

// Common passwords from various data breaches and our examples
export const COMMON_PASSWORD_SUBSTRINGS = [
  'password',
  '123',
  'qwerty',
  'letmein',
  'admin',
  'welcome',
  'asdf',
  // Add our examples to prevent their use
  'purple-giraffe-eats-pizza-2024',
  'dancing-clouds-make-rainbows',
  'winter-fox-jumps-quietly-99',
  // Add more as needed
];

export function validatePassword(password: string): PasswordValidationResult {
  if (!password) {
    return {
      isValid: false,
      error: 'Password is required',
    };
  }

  // NIST guidelines: Minimum length check
  if (password.length < MIN_PASSWORD_LENGTH) {
    return {
      isValid: false,
      error: `Password must be at least ${MIN_PASSWORD_LENGTH} characters long`,
    };
  }

  // NIST guidelines: Maximum length check
  if (password.length > MAX_PASSWORD_LENGTH) {
    return {
      isValid: false,
      error: `Password must not exceed ${MAX_PASSWORD_LENGTH} characters`,
    };
  }

  // Check for common substrings in passwords
  for (const substring of COMMON_PASSWORD_SUBSTRINGS) {
    if (password.toLowerCase().includes(substring)) {
      return {
        isValid: false,
        error: 'This password is too common. Please choose a stronger password',
      };
    }
  }

  // Check for repeating characters (e.g., 'aaa', '111')
  if (/(.)\1{2,}/.test(password)) {
    return {
      isValid: false,
      error: 'Password cannot contain repeating characters (e.g., "aaa", "111")',
    };
  }

  // Check for sequential characters (e.g., 'abc', '123')
  if (
    /(?:abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)/.test(
      password.toLowerCase(),
    )
  ) {
    return {
      isValid: false,
      error: 'Password cannot contain sequential characters (e.g., "abc", "123")',
    };
  }

  // Check for spaces at beginning or end
  if (password.startsWith(' ') || password.endsWith(' ')) {
    return {
      isValid: false,
      error: 'Password cannot begin or end with spaces',
    };
  }

  return { isValid: true };
}
