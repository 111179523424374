export const ROUTES = {
  serverSettings: {
    index: '/server',
    edit: '/server/edit',
  },
  config: '/config',
  eval: '/eval',
  guardrails: '/guardrails',
  login: '/login',
  organization: '/organizations',
  redteam: {
    dashboard: '/redteam/dashboard',
    report: '/redteam/report',
    vulnerabilities: '/redteam/vulnerabilities',
    configs: '/redteam/configurations',
  },
  jobs: '/jobs',
  verifyEmail: '/verify-email',
  welcome: '/welcome',
  passwordReset: {
    request: '/reset-password',
    confirm: '/reset-password/confirm',
  },
  loggedInPasswordReset: '/logged-in-reset-password',
};

export const EVAL_ID_SCAN_PATTERN = /\s+from scan:\s+(eval-[\w-:.]+)$/;
