import { Paper, Typography } from '@mui/material';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import type { TimeRange } from '../types';
import {
  formatTooltipLabel,
  formatXAxisTick,
  generateTimePoints,
  getXAxisInterval,
} from '../utils/time';

interface ThreatTypesChartProps {
  data: Record<string, Array<{ hour: string; count: number }>>;
  timeRange: TimeRange;
}

interface DataPoint {
  hour: string;
  [key: string]: string | number;
}

const THREAT_COLORS = {
  pii: '#8884d8',
  harm: '#e57373',
  guard: '#ffc658',
};

const THREAT_LABELS = {
  pii: 'PII Detection',
  harm: 'Harmful Content',
  guard: 'Prompt Injection',
};

export default function ThreatTypesChart({ data, timeRange }: ThreatTypesChartProps) {
  // Generate array of all points in the time range in UTC
  const allPoints = generateTimePoints(timeRange);

  // Create a map of existing data points for each threat type
  const dataMaps = Object.fromEntries(
    Object.entries(data).map(([type, series]) => [
      type,
      new Map(series.map((point) => [point.hour, point.count])),
    ]),
  );

  // Fill in missing data points with zeros
  const filledData = allPoints.map((hour) => ({
    hour,
    ...Object.fromEntries(Object.keys(data).map((type) => [type, dataMaps[type]?.get(hour) || 0])),
  })) as DataPoint[];

  // Calculate the maximum value across all data points
  const maxValue = Math.max(
    ...filledData.flatMap((point) => Object.keys(data).map((type) => Number(point[type]) || 0)),
  );

  // Add 20% padding to the max value to ensure everything fits
  const yAxisMax = Math.ceil(maxValue * 1.2);

  return (
    <Paper sx={{ p: 2, height: 400 }}>
      <Typography variant="h6" gutterBottom>
        Threat Types Flagged
      </Typography>
      <ResponsiveContainer width="100%" height="90%">
        <AreaChart data={filledData}>
          <defs>
            {Object.entries(THREAT_COLORS).map(([type, color]) => (
              <linearGradient key={type} id={`${type}Gradient`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                <stop offset="95%" stopColor={color} stopOpacity={0} />
              </linearGradient>
            ))}
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="hour"
            tickFormatter={(value) => formatXAxisTick(value, timeRange)}
            interval={getXAxisInterval(timeRange)}
          />
          <YAxis allowDecimals={false} domain={[0, yAxisMax]} />
          <Tooltip
            labelFormatter={formatTooltipLabel}
            formatter={(value: number, name: string) => [
              value,
              THREAT_LABELS[name as keyof typeof THREAT_LABELS] || name,
            ]}
          />
          <Legend
            formatter={(value) => THREAT_LABELS[value as keyof typeof THREAT_LABELS] || value}
          />
          {Object.entries(THREAT_COLORS).map(([type, color]) => (
            <Area
              key={type}
              type="monotone"
              dataKey={type}
              stroke={color}
              fill={`url(#${type}Gradient)`}
              name={type}
              stackId="1"
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </Paper>
  );
}
