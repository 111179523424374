import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

interface ErrorAlertProps {
  error: Error | unknown;
}

export default function ErrorAlert({ error }: ErrorAlertProps) {
  const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';

  return (
    <Box mb={2}>
      <Alert severity="error">{errorMessage}</Alert>
    </Box>
  );
}
