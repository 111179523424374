import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { startJob } from '@cloud-ui/utils/api/jobs';
import type { RedteamConfig } from '@shared/dto/redteamConfigs';

export function useStartJob() {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: ({
      config,
      configId,
      getUnifiedConfig,
    }: {
      config: RedteamConfig;
      configId?: string;
      getUnifiedConfig: (config: RedteamConfig) => any;
    }) => startJob(getUnifiedConfig(config), configId, config.runOptions),
    onSuccess: (data) => {
      showToast('Job started', 'success');
      queryClient.setQueryData(['jobs', data.id], () => data);
      navigate(`${ROUTES.jobs}/${data.id}`);
    },
    onError: (error) => {
      showToast(`Job Failed to start: ${error.message}`, 'error');
    },
  });
}
