import type { ProviderOptions } from '@promptfoo/types';
import { ProviderOptionsSchema, ProviderSchema } from '@promptfoo/validators/providers';
import { z } from 'zod';

export interface ServerSettingDTO {
  key: ServerSettingsKeys;
  value: any;
}

export enum ServerSettingsKeys {
  authConfig = 'authConfig',
  redteamProvider = 'redteamProvider',
  unalignedProvider = 'unalignedProvider',
}

export enum AuthMethod {
  email = 'email',
  azureAdOidc = 'azureAdOidc',
  iap = 'iap',
  password = 'password',
  magicLink = 'magicLink',
}

export interface AuthConfig {
  enabled: boolean;
}

export interface AuthSettings {
  [AuthMethod.password]?: AuthConfig;
  [AuthMethod.magicLink]?: AuthConfig;
  [AuthMethod.iap]?: AuthConfig;
  [AuthMethod.email]?: SmtpConfig;
  [AuthMethod.azureAdOidc]?: AzureAdConfig;
}

export const simpleAuthConfigSchema = z.object({
  enabled: z.boolean(),
});

export const smtpSchema = z.object({
  enabled: z.boolean(),
  host: z.string().min(1, 'SMTP Host is required'),
  port: z.number().min(1, 'Port is required'),
  auth: z.object({
    user: z.string().min(1, 'Username is required'),
    pass: z.string().min(1, 'Password is required'),
  }),
  from: z.string().min(5, 'Invalid email address'),
});

export const azureAdOidcSchema = z.object({
  enabled: z.boolean(),
  clientId: z.string().min(1, 'Client ID is required'),
  clientSecret: z.string().min(1, 'Client Secret is required'),
  tenantId: z.string().min(1, 'Tenant ID is required'),
  callbackUrl: z.string().url('Must be a valid URL'),
});

export type SmtpConfig = z.infer<typeof smtpSchema>;
export type AzureAdConfig = z.infer<typeof azureAdOidcSchema>;

export const ServerSettingsSchemas: Record<ServerSettingsKeys, z.ZodType<any>> = {
  [ServerSettingsKeys.authConfig]: z.object({
    [AuthMethod.email]: smtpSchema.optional(),
    [AuthMethod.azureAdOidc]: azureAdOidcSchema.optional(),
    [AuthMethod.iap]: simpleAuthConfigSchema.optional(),
    [AuthMethod.password]: simpleAuthConfigSchema.optional(),
  }),
  [ServerSettingsKeys.redteamProvider]: z.union([z.string(), ProviderOptionsSchema as any]),
  [ServerSettingsKeys.unalignedProvider]: z.union([z.string(), ProviderOptionsSchema as any]),
};
