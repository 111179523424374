import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getAuthToken, API_BASE_URL } from '@cloud-ui/utils/api';
import { fetchEventSource } from '@microsoft/fetch-event-source';

// Hook for managing job logs via SSE using fetchEventSource
export function useJobLogs(jobId: string | null | undefined) {
  const queryClient = useQueryClient();
  const [error, setError] = useState<Error | null>(null);

  // Add this query to access the logs
  const { data: logs = [] } = useQuery({
    queryKey: ['jobLogs', jobId],
    // Initialize with empty array
    initialData: [],
  });

  useEffect(() => {
    setError(null);
    if (!jobId) {
      return;
    }

    const token = getAuthToken();
    if (!token) {
      setError(new Error('No authentication token found'));
      return;
    }

    const controller = new AbortController();

    const connectToEventSource = () => {
      fetchEventSource(`${API_BASE_URL}/api/jobs/${jobId}/logs`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
        },
        signal: controller.signal,
        openWhenHidden: true,
        onopen: async (response) => {
          if (
            response.status !== 200 ||
            !response.headers.get('Content-Type')?.includes('text/event-stream')
          ) {
            throw new Error(`Unexpected response: ${response.status}`);
          }
          console.log('SSE connection established.');
        },
        onmessage: (event) => {
          if (event.data.startsWith(':')) {
            // Heartbeat message, can be ignored or used to keep connection alive
            console.log('Heartbeat received');
            return;
          }

          try {
            const logLine = JSON.parse(event.data);
            setError(null);

            queryClient.setQueryData(['jobLogs', jobId], (oldData: string[] | undefined) => {
              if (!oldData) {
                return oldData;
              }
              return [...(oldData ?? []), logLine.message];
            });
          } catch (err) {
            setError(new Error('Failed to parse log data ' + err));
          }
        },
        onerror: (err) => {
          console.error('SSE connection error:', err);
          setError(new Error('Connection to logs failed: ' + err));

          controller.abort();
        },
      });
    };

    connectToEventSource();

    return () => {
      controller.abort();
    };
  }, [jobId, queryClient]);

  // Return logs along with error
  return { logs, error };
}
