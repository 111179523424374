import { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useConfig } from '@cloud-ui/contexts/ConfigContext';
import { ENABLE_SERVER_SETTINGS_PAGE } from '@cloud-ui/utils/serverSettings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import KeyIcon from '@mui/icons-material/Key';
import LockResetIcon from '@mui/icons-material/LockReset';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { AuthMethod } from '@shared/dto/serverSettings';
import { AuthContext } from '../contexts/AuthContext';

export default function LoggedInAs() {
  const navigate = useNavigate();
  const { user, logout, organization } = useContext(AuthContext) || {};
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const config = useConfig();

  const passwordAuthEnabled = config?.authMethods?.includes(AuthMethod.password);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();
    logout?.();
  };

  const handleCliLoginInfo = () => {
    navigate(ROUTES.welcome);
    handleClose();
  };

  const handlePasswordReset = () => {
    navigate(ROUTES.loggedInPasswordReset);
    handleClose();
  };

  if (!user) {
    return (
      <div>
        <IconButton
          aria-label="User not logged in"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar sx={{ width: '1em', height: '1em' }} />
        </IconButton>
      </div>
    );
  }

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Avatar sx={{ width: 32, height: 32, bgcolor: alpha('#1976d2', 0.8), fontSize: '0.9rem' }}>
          {user.name ? user.name[0].toUpperCase() : ''}
        </Avatar>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            minWidth: 220,
          },
        }}
      >
        <Box sx={{ p: 2, pb: 3 }}>
          <Box
            sx={{
              bgcolor: 'background.paper',
              boxShadow: '0px 2px 10px rgba(0,0,0,0.08)',
              borderRadius: 1,
              p: 1.5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <AccountCircleIcon sx={{ fontSize: 40, color: 'primary.main', mr: 1.5 }} />
            <Box>
              <Typography variant="subtitle2" fontWeight="bold" color="text.primary">
                Logged in as
              </Typography>
              <Typography variant="body2" color="text.primary">
                {user.name || user.email}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {user.email}
              </Typography>
            </Box>
          </Box>
        </Box>
        <MenuItem component={Link} to={`/organizations/${organization?.id}`}>
          <ListItemIcon>
            <BusinessIcon fontSize="small" color="action" />
          </ListItemIcon>
          <ListItemText
            primary={organization?.name}
            secondary="Organization"
            primaryTypographyProps={{ variant: 'body2' }}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleCliLoginInfo}>
          <ListItemIcon>
            <KeyIcon fontSize="small" color="action" />
          </ListItemIcon>
          <ListItemText
            primary="CLI Login Information"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        {passwordAuthEnabled && (
          <MenuItem onClick={handlePasswordReset}>
            <ListItemIcon>
              <LockResetIcon fontSize="small" color="action" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Reset Password
                  <Chip
                    label="New"
                    size="small"
                    color="primary"
                    sx={{
                      height: 20,
                      fontSize: '0.75rem',
                    }}
                  />
                </Box>
              }
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {ENABLE_SERVER_SETTINGS_PAGE && (
          <MenuItem component={Link} to={ROUTES.serverSettings.index}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" color="action" />
            </ListItemIcon>
            <ListItemText primary="Server Settings" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" color="action" />
          </ListItemIcon>
          <ListItemText primary="Logout" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </div>
  );
}
