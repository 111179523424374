import PublicIcon from '@mui/icons-material/Public';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface PublicChipProps {
  isPublic: boolean;
}

export const PublicChip: React.FC<PublicChipProps> = ({ isPublic }) => {
  if (!isPublic) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        px: 1,
        py: 0.5,
        minHeight: 40,
      }}
    >
      <PublicIcon fontSize="small" sx={{ mr: 1, opacity: 0.7 }} />
      <Typography variant="body2" sx={{ mr: 1 }}>
        Public
      </Typography>
    </Box>
  );
};
