import { useState, useEffect } from 'react';
import { callApi } from '@cloud-ui/utils/api';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Chip,
  Typography,
  Box,
} from '@mui/material';
import type { TimeRange } from '../types';

// Match colors from ThreatTypesChart
const THREAT_COLORS = {
  pii: '#8884d8',
  harm: '#e57373',
  guard: '#ffc658',
};

interface Event {
  id: string;
  timestamp: string;
  modelId?: string;
  applicationId?: string;
  content?: string;
  guardrailsType: string;
  guardrailsResponse: any;
  latencyMs: number;
  success: boolean;
  flagged: boolean;
  error?: string;
}

interface EventsTableProps {
  timeRange: TimeRange;
}

export default function EventsTable({ timeRange }: EventsTableProps) {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const response = await callApi(
          `/guardrails/v1/events?timeRange=${timeRange}&page=${page + 1}&limit=${rowsPerPage}`,
        );
        if (!response.ok) {
          throw new Error('Failed to fetch events');
        }
        const { data } = await response.json();
        setEvents(data.events);
        setTotal(data.pagination.total);
      } catch (error) {
        console.error('Error fetching events:', error);
        setError(error instanceof Error ? error.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [timeRange, page, rowsPerPage]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Application</TableCell>
              <TableCell>Content</TableCell>
              <TableCell>Latency</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event) => (
              <TableRow key={event.id}>
                <TableCell>{new Date(event.timestamp).toLocaleString()}</TableCell>
                <TableCell>
                  <Chip
                    label={event.guardrailsType}
                    sx={{
                      backgroundColor:
                        THREAT_COLORS[event.guardrailsType as keyof typeof THREAT_COLORS] ||
                        '#9e9e9e',
                      color: event.guardrailsType === 'guard' ? 'rgba(0, 0, 0, 0.87)' : '#fff',
                    }}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  {event.success ? (
                    event.flagged ? (
                      <Chip label="Flagged" color="warning" size="small" />
                    ) : (
                      <Chip label="Safe" color="success" size="small" />
                    )
                  ) : (
                    <Chip label="Error" color="error" size="small" />
                  )}
                </TableCell>
                <TableCell>{event.modelId || '-'}</TableCell>
                <TableCell>{event.applicationId || '-'}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      maxWidth: 200,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Typography variant="body2">{event.content || '-'}</Typography>
                  </Box>
                </TableCell>
                <TableCell>{event.latencyMs}ms</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
