import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Paper, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { validatePassword } from '@shared/auth/password';
import { callApi } from '../../../utils/api';

export default function ResetPassword() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    setLoading(true);

    const validation = validatePassword(password);
    if (!validation.isValid) {
      setError(validation.error || 'Invalid password');
      setLoading(false);
      return;
    }

    try {
      const response = await callApi('/users/reset-password/confirm', {
        method: 'POST',
        body: JSON.stringify({ token, password }),
      });
      const data = (await response.json()) as { message?: string; error?: string };

      if (response.ok) {
        setSuccess(true);
      } else {
        setError(data.error || data.message || 'An error occurred while resetting password');
      }
    } catch (err) {
      console.error('Error during password reset:', err);
      setError('An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  const isValidPassword = (password: string) => {
    return password.length >= 8;
  };

  const passwordRequirements = (
    <Box sx={{ maxWidth: 300 }}>
      <Typography variant="body2" fontWeight="medium" gutterBottom>
        Password Requirements:
      </Typography>
      <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
        <li>At least 12 characters long</li>
        <li>Maximum 64 characters</li>
        <li>No repeating characters (e.g., "aaa")</li>
        <li>No sequential patterns (e.g., "abc", "123")</li>
        <li>Cannot be a commonly used password</li>
      </ul>
      <Typography variant="body2" sx={{ mt: 1, mb: 1 }} fontWeight="medium">
        Tip: Create a memorable passphrase using 4+ random words
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
        Good examples:
      </Typography>
      <ul style={{ margin: 0, paddingLeft: '20px', color: 'text.secondary' }}>
        <li>purple-giraffe-eats-pizza-2024</li>
        <li>dancing-clouds-make-rainbows!</li>
        <li>winter-fox-jumps-quietly-99</li>
      </ul>
    </Box>
  );

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>

        {success ? (
          <Box mt={2}>
            <Typography>Your password has been successfully reset.</Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate('/login')}
              sx={{ mt: 2 }}
            >
              Return to Login
            </Button>
          </Box>
        ) : (
          <Box component="form" onSubmit={handleSubmit} mt={2}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter your new password below.
            </Typography>

            <TextField
              disabled={loading}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  New Password
                  <Tooltip title={passwordRequirements} arrow placement="right">
                    <InfoIcon sx={{ fontSize: 18, color: 'action.active', ml: 0.5 }} />
                  </Tooltip>
                </Box>
              }
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={Boolean(error)}
              helperText={error || 'Enter your new password'}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              sx={{ mb: 2 }}
            />

            <Button
              type="submit"
              disabled={loading || !isValidPassword(password)}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 2 }}
            >
              Reset Password
            </Button>

            <Button
              fullWidth
              variant="text"
              onClick={() => navigate('/login')}
              sx={{ textTransform: 'none' }}
            >
              Back to Login
            </Button>
          </Box>
        )}
      </Paper>
    </Container>
  );
}
