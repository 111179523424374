import ReactMarkdown from 'react-markdown';
import analyzingPanda from '@cloud-ui/assets/analyzing_panda.jpg';
import { Typography, Alert, AlertTitle, Box } from '@mui/material';
import { type RemediationDTO } from '@shared/dto';
import Suggestion, { type SuggestionType } from './Suggestion';

export default function ExplanationTab({ remediation }: { remediation: RemediationDTO }) {
  let remediationIsText = true;
  const remediationString = remediation?.remediation;
  let newRemediation;

  const remediations: {
    immediate: SuggestionType[];
    near_term: SuggestionType[];
    long_term: SuggestionType[];
  } = {
    immediate: [],
    near_term: [],
    long_term: [],
  };
  try {
    newRemediation = JSON.parse(remediationString);
    remediationIsText = false;
    newRemediation.suggestions.forEach((suggestion: SuggestionType) => {
      remediations[suggestion.timeframe as keyof typeof remediations].push(suggestion);
    });
  } catch {
    console.log('Error parsing remediation, it is the old text version');
  }
  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', p: 3 }}>
      {/* Navigation Links */}
      <Box
        sx={{
          mb: 4,
          p: 3,
          borderRadius: 2,
          backgroundColor: 'background.paper',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontWeight: 600,
            color: 'primary.main',
            borderBottom: '2px solid',
            borderColor: 'primary.main',
            pb: 1,
          }}
        >
          Quick Navigation
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
          }}
        >
          {[
            {
              href: '#remediation',
              text: 'Remediation Suggestions',
              icon: '🔍',
            },
            {
              href: '#generic-description',
              text: 'Vulnerability Background',
              icon: '📋',
            },
          ].map((link) => (
            <a key={link.href} href={link.href} style={{ textDecoration: 'none' }}>
              <Box
                className="navigation-link"
                sx={{
                  p: 2,
                  borderRadius: 1,
                  transition: 'all 0.2s ease',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  bgcolor: 'background.paper',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: 1,
                    bgcolor: 'primary.main',
                    '& .nav-text': {
                      color: 'common.white',
                    },
                  },
                }}
              >
                <Typography variant="body1" component="span">
                  {link.icon}
                </Typography>
                <Typography
                  className="nav-text"
                  variant="body2"
                  sx={{
                    color: 'text.primary',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                  }}
                >
                  {link.text}
                </Typography>
              </Box>
            </a>
          ))}
        </Box>
      </Box>

      {/* Remediation Section */}
      <Box id="remediation" sx={{ mb: 6 }}>
        {remediation?.remediation ? (
          remediationIsText ? (
            <Box
              sx={{
                backgroundColor: 'background.paper',
                p: 3,
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <ReactMarkdown>{remediation?.remediation}</ReactMarkdown>
            </Box>
          ) : (
            <>
              {/* Vulnerability Analysis */}
              {newRemediation.vulnerability_description && (
                <Box sx={{ mb: 4 }}>
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 2,
                      color: 'text.primary',
                      fontWeight: 600,
                      borderBottom: '2px solid',
                      borderColor: 'primary.main',
                      pb: 1,
                    }}
                  >
                    Vulnerability Analysis
                  </Typography>
                  <Typography sx={{ lineHeight: 1.6 }}>
                    {newRemediation.vulnerability_description}
                  </Typography>
                </Box>
              )}

              {/* Remediation Categories */}
              <Typography
                variant="h4"
                sx={{
                  mt: 6,
                  mb: 4,
                  color: 'primary.main',
                  fontWeight: 700,
                }}
              >
                Suggestions
              </Typography>

              {/* Immediate Improvements */}
              {remediations.immediate.length > 0 && (
                <Box sx={{ mb: 6 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 3,
                      color: 'error.main',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    Immediate Improvements ({remediations.immediate.length})
                  </Typography>
                  {remediations.immediate.map((suggestion: SuggestionType) => (
                    <Suggestion key={suggestion.title} suggestion={suggestion} />
                  ))}
                </Box>
              )}

              {/* Near-term Improvements */}
              {remediations.near_term.length > 0 && (
                <Box sx={{ mb: 6 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 3,
                      color: 'warning.main',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    Near-term Improvements ({remediations.near_term.length})
                  </Typography>
                  {remediations.near_term.map((suggestion: SuggestionType) => (
                    <Suggestion key={suggestion.title} suggestion={suggestion} />
                  ))}
                </Box>
              )}

              {/* Long-term Improvements */}
              {remediations.long_term.length > 0 && (
                <Box sx={{ mb: 6 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 3,
                      color: 'info.main',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    Long-term Improvements ({remediations.long_term.length})
                  </Typography>
                  {remediations.long_term.map((suggestion: SuggestionType) => (
                    <Suggestion key={suggestion.title} suggestion={suggestion} />
                  ))}
                </Box>
              )}
            </>
          )
        ) : (
          <Alert
            severity="info"
            icon={
              <Box
                component="img"
                src={analyzingPanda}
                alt="Analyzing Panda"
                sx={{
                  width: 48,
                  height: 48,
                  objectFit: 'cover',
                  borderRadius: '50%',
                  border: '2px solid',
                  borderColor: 'info.main',
                }}
              />
            }
            sx={{
              mb: 4,
              alignItems: 'center',
              borderRadius: 2,
              boxShadow: 2,
            }}
          >
            <AlertTitle sx={{ fontWeight: 600 }}>Analysis in Progress</AlertTitle>
            <Typography>
              Analyzing results and generating custom remediations for you... In the meantime, you
              can read more about the issue below.
            </Typography>
          </Alert>
        )}
      </Box>

      {/* Generic Description Section */}
      <Box
        id="generic-description"
        sx={{
          backgroundColor: 'background.paper',
          p: 3,
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            color: 'text.primary',
            fontWeight: 600,
          }}
        >
          Vulnerability Background
        </Typography>
        {remediation?.genericDescription ? (
          <ReactMarkdown>{remediation.genericDescription}</ReactMarkdown>
        ) : (
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            No generic description available.
          </Typography>
        )}
      </Box>
    </Box>
  );
}
