import React, { useContext } from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import DarkMode from '@app/components/DarkMode';
import LoggedInAs from '@cloud-ui/components/LoggedInAs';
import Logo from '@cloud-ui/components/Logo';
import { ROUTES } from '@cloud-ui/constants';
import { useConfig } from '@cloud-ui/contexts/ConfigContext';
import type { ButtonProps } from '@mui/material';
import { AppBar, Toolbar, Button, Box, MenuItem, Popper, Paper, MenuList } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AuthContext } from '../contexts/AuthContext';

const NavButton = styled(Button)<ButtonProps & RouterLinkProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.active': {
    backgroundColor: theme.palette.action.selected,
  },
}));

function NavLink({ href, label, onClick }: { href: string; label: string; onClick?: () => void }) {
  const location = useLocation();
  const isActive = location.pathname.startsWith(href);

  return (
    <NavButton
      component={RouterLink}
      to={href}
      className={isActive ? 'active' : ''}
      onClick={onClick}
    >
      {label}
    </NavButton>
  );
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[1],
  marginBottom: theme.spacing(2),
}));

const NavToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
});

const NavSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
});

interface NavigationProps {
  darkMode: boolean;
  onToggleDarkMode: () => void;
}

const useMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>, menuId: string) => {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (!relatedTarget?.closest(`#${menuId}`)) {
      setAnchorEl(null);
    }
  };

  return {
    anchorEl,
    setAnchorEl,
    handleMouseEnter,
    handleMouseLeave,
  };
};

function RedteamMenu() {
  const { anchorEl, setAnchorEl, handleMouseEnter, handleMouseLeave } = useMenu();

  return (
    <>
      <Button
        id="redteam-button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={(e) => handleMouseLeave(e, 'redteam-menu')}
        aria-controls={anchorEl ? 'redteam-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl)}
        sx={{
          color: 'text.primary',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        Redteam
      </Button>
      <Popper
        id="redteam-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        placement="bottom-start"
        onMouseLeave={(e) => handleMouseLeave(e, 'redteam-button')}
      >
        <Paper>
          <MenuList>
            <MenuItem
              component={RouterLink}
              to={ROUTES.redteam.configs}
              onClick={() => setAnchorEl(null)}
            >
              Test Setup
            </MenuItem>
            <MenuItem component={RouterLink} to={ROUTES.jobs} onClick={() => setAnchorEl(null)}>
              Jobs
            </MenuItem>
          </MenuList>
        </Paper>
      </Popper>
    </>
  );
}

function Navigation({ darkMode, onToggleDarkMode }: NavigationProps) {
  const { user } = useContext(AuthContext) || {};
  const { enableServerSideJobs } = useConfig();

  return (
    <StyledAppBar position="static" elevation={0}>
      <NavToolbar>
        <NavSection>
          <Logo />
          {user && (
            <>
              {enableServerSideJobs ? (
                <RedteamMenu />
              ) : (
                <NavLink href={ROUTES.redteam.configs} label="Redteam" />
              )}
              <NavLink href={ROUTES.redteam.dashboard} label="Dashboard" />
              <NavLink href={ROUTES.redteam.vulnerabilities} label="Vulnerabilities" />
              <NavLink href={ROUTES.redteam.report} label="Reports" />
              <NavLink href={ROUTES.eval} label="Evals" />
            </>
          )}
        </NavSection>
        <NavSection>
          <DarkMode onToggleDarkMode={onToggleDarkMode} />
          <LoggedInAs />
        </NavSection>
      </NavToolbar>
    </StyledAppBar>
  );
}

export default Navigation;
