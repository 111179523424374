import { useState, useEffect } from 'react';
import { callApi } from '@cloud-ui/utils/api';
import { Container, Grid } from '@mui/material';
import DetectionRateChart from './components/DetectionRateChart';
import EventsTable from './components/EventsTable';
import LatencyStats from './components/LatencyStats';
import StatCard from './components/StatCard';
import ThreatTypesChart from './components/ThreatTypesChart';
import TimeRangeSelector from './components/TimeRangeSelector';
import TimeSeriesChart from './components/TimeSeriesChart';
import TypeDistributionChart from './components/TypeDistributionChart';
import type { TimeRange } from './types';

interface TimeSeriesDataPoint {
  hour: string;
  total: number;
  flagged: number;
  unflagged: number;
  detectionRate: number;
}

export default function GuardrailsDashboard() {
  const [timeRange, setTimeRange] = useState<TimeRange>('24h');
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data for time range:', timeRange);
        const response = await callApi(`/guardrails/v1/analytics?timeRange=${timeRange}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        console.log('Fetched analytics data:', { timeRange, data });
        setData(data);
      } catch (error) {
        console.error('Error fetching analytics data:', error);
        setError(error instanceof Error ? error.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // Refresh every minute
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, [timeRange]);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  if (!data) {
    return null;
  }

  // Calculate trends based on the data
  const calculateTrend = (
    current: number,
    previous: number,
  ): { trend: 'up' | 'down' | undefined; trendValue: string | undefined } => {
    if (previous === 0) {
      if (current === 0) {
        return { trend: undefined, trendValue: undefined };
      }
      // If we went from 0 to something, show "New"
      return { trend: 'up', trendValue: 'New' };
    }
    const change = ((current - previous) / previous) * 100;
    // Handle infinity and unreasonable percentage changes
    if (!Number.isFinite(change) || Math.abs(change) > 1000) {
      return { trend: change > 0 ? 'up' : 'down', trendValue: 'N/A' };
    }
    return {
      trend: change >= 0 ? 'up' : 'down',
      trendValue: `${Math.abs(change).toFixed(1)}%`,
    };
  };

  const detectionRate = data.total > 0 ? ((data.flagged / data.total) * 100).toFixed(1) : '0';
  const prevDetectionRate =
    data.previousPeriod.total > 0
      ? (data.previousPeriod.flagged / data.previousPeriod.total) * 100
      : 0;

  const totalTrend = calculateTrend(data.total, data.previousPeriod.total);
  const flaggedTrend = calculateTrend(data.flagged, data.previousPeriod.flagged);
  const detectionTrend = calculateTrend(Number.parseFloat(detectionRate), prevDetectionRate);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <TimeRangeSelector value={timeRange} onChange={setTimeRange} />
      <Grid container spacing={3}>
        {/* Stats Cards */}
        <Grid item xs={12} md={3}>
          <StatCard
            title="Total Requests"
            value={data.total.toLocaleString()}
            trend={totalTrend.trend}
            trendValue={totalTrend.trendValue}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            title="Flagged Requests"
            value={data.flagged.toLocaleString()}
            trend={flaggedTrend.trend}
            trendValue={flaggedTrend.trendValue}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            title="Detection Rate"
            value={`${detectionRate}%`}
            trend={detectionTrend.trend}
            trendValue={detectionTrend.trendValue}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <LatencyStats latency={data.latency} />
        </Grid>

        {/* Charts */}
        <Grid item xs={12} md={6}>
          <TimeSeriesChart data={data.timeSeries} timeRange={timeRange} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DetectionRateChart
            data={data.timeSeries.map((point: TimeSeriesDataPoint) => ({
              hour: point.hour,
              detectionRate: point.detectionRate,
            }))}
            timeRange={timeRange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ThreatTypesChart data={data.threatTimeSeries} timeRange={timeRange} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TypeDistributionChart data={data.byType} />
        </Grid>

        {/* Events Table */}
        <Grid item xs={12}>
          <EventsTable timeRange={timeRange} />
        </Grid>
      </Grid>
    </Container>
  );
}
