import { Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import type { TimeRange } from '../types';
import {
  formatTooltipLabel,
  formatXAxisTick,
  generateTimePoints,
  getXAxisInterval,
} from '../utils/time';

interface DetectionRateChartProps {
  data: Array<{
    hour: string;
    detectionRate: number;
  }>;
  timeRange: TimeRange;
}

export default function DetectionRateChart({ data, timeRange }: DetectionRateChartProps) {
  const theme = useTheme();

  // Generate array of all points in the time range in UTC
  const allPoints = generateTimePoints(timeRange);

  // Create a map of existing data points
  const dataMap = new Map(data.map((point) => [point.hour, point]));

  // Fill in missing data points with zeros
  const filledData = allPoints.map((hour) => ({
    hour,
    detectionRate: dataMap.get(hour)?.detectionRate || 0,
  }));

  return (
    <Paper sx={{ p: 2, height: 400 }}>
      <Typography variant="h6" gutterBottom>
        Total Detection Rate
      </Typography>
      <ResponsiveContainer width="100%" height="90%">
        <AreaChart data={filledData}>
          <defs>
            <linearGradient id="detectionRateGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={theme.palette.error.main} stopOpacity={0.8} />
              <stop offset="95%" stopColor={theme.palette.error.main} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="hour"
            tickFormatter={(value) => formatXAxisTick(value, timeRange)}
            interval={getXAxisInterval(timeRange)}
          />
          <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} />
          <Tooltip
            labelFormatter={formatTooltipLabel}
            formatter={(value: number) => [`${value.toFixed(1)}%`, 'Detection Rate']}
          />
          <Legend />
          <Area
            type="monotone"
            dataKey="detectionRate"
            stroke={theme.palette.error.main}
            fill="url(#detectionRateGradient)"
            name="Detection Rate"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Paper>
  );
}
