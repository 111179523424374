import { Paper, Typography } from '@mui/material';

interface StatCardProps {
  title: string;
  value: string | number;
  trend?: 'up' | 'down';
  trendValue?: string;
}

export default function StatCard({ title, value, trend, trendValue }: StatCardProps) {
  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Typography variant="subtitle2" color="text.secondary">
        {title}
      </Typography>
      <Typography variant="h4" component="div" sx={{ mt: 1 }}>
        {value}
      </Typography>
      {trend && (
        <Typography
          variant="body2"
          color={trend === 'up' ? 'error.main' : 'success.main'}
          sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
        >
          {trend === 'up' ? '↑' : '↓'} {trendValue}
        </Typography>
      )}
    </Paper>
  );
}
