import React, { useState, useEffect } from 'react';
import { testProvider } from '@cloud-ui/utils/api/providers';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import type { ProviderOptions } from '@promptfoo/types';
import BrowserAutomationConfiguration from './BrowserAutomationConfiguration';
import CustomProviderConfiguration from './CustomProviderConfiguration';
import HttpEndpointConfiguration from './HttpEndpointConfiguration';
import TestTargetConfiguration from './TestTargetConfiguration';
import WebSocketEndpointConfiguration from './WebSocketEndpointConfiguration';

export function defaultHttpTarget(): ProviderOptions {
  return {
    id: 'http',
    config: {
      url: '',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        message: '{{prompt}}',
      }),
    },
  };
}

interface ProviderProps {
  onActionButtonClick: () => void;
  onBack: () => void;
  provider: ProviderOptions | undefined;
  setProvider: (provider: ProviderOptions) => void;
  opts?: {
    availableProviderIds?: string[];
    description?: React.ReactNode;
    disableNameField?: boolean;
    disableTitle?: boolean;
    actionButtonText?: string;
    defaultProviderId?: string;
    specialProviderType?: string;
    defaultRequestTransform?: string;
  };
}

const predefinedProviders = [
  { value: '', label: 'Select a provider' },
  { value: 'http', label: 'HTTP/HTTPS Endpoint' },
  { value: 'https', label: 'HTTPS Endpoint' },
  { value: 'websocket', label: 'WebSocket Endpoint' },
  { value: 'browser', label: 'Web Browser Automation' },
  { value: 'openai:gpt-4o-mini', label: 'OpenAI GPT-4o Mini' },
  { value: 'openai:gpt-4o', label: 'OpenAI GPT-4o' },
  { value: 'claude-3-5-sonnet-latest', label: 'Anthropic Claude 3.5 Sonnet' },
  { value: 'vertex:gemini-pro', label: 'Google Vertex AI Gemini Pro' },
];

const customProviderOption = { value: 'custom', label: 'Custom Provider' };

const knownProviderIds = predefinedProviders
  .map((provider) => provider.value)
  .filter((value) => value !== '');

const validateUrl = (url: string, type: 'http' | 'websocket' = 'http'): boolean => {
  try {
    const parsedUrl = new URL(url);
    if (type === 'http') {
      return ['http:', 'https:'].includes(parsedUrl.protocol);
    } else if (type === 'websocket') {
      return ['ws:', 'wss:'].includes(parsedUrl.protocol);
    }
    return false;
  } catch {
    return false;
  }
};

const requiresTransformResponse = (provider: ProviderOptions) => {
  return provider.id?.startsWith('http') || provider.id === 'websocket';
};

export default function ProviderEditor({
  onActionButtonClick,
  onBack,
  provider,
  setProvider,
  opts = {},
}: ProviderProps) {
  const theme = useTheme();
  const {
    disableNameField = false,
    description,
    disableTitle = false,
    actionButtonText,
    defaultProviderId,
    availableProviderIds,
    specialProviderType,
    defaultRequestTransform,
  } = opts;
  const [testingProvider, setTestingProvider] = useState(false);
  const [testResult, setTestResult] = useState<{
    success?: boolean;
    message: string;
    suggestions?: string[];
    providerResponse?: {
      raw: string;
      output: string;
      sessionId?: string;
      metadata?: {
        headers?: Record<string, string>;
      };
    };
    unalignedProviderResult?: {
      outputs: string[];
      statusCode?: number;
    };
    redteamProviderResult?: {
      raw: string;
      output: string;
      sessionId?: string;
      metadata?: {
        headers?: Record<string, string>;
      };
    };
  } | null>(null);

  const selectOptions = [...predefinedProviders, customProviderOption].filter(
    (provider) => !availableProviderIds || availableProviderIds?.includes(provider.value),
  );
  const [hasTestedProvider, setHasTestedProvider] = useState(false);
  const [bodyError, setBodyError] = useState<string | null>(null);
  const [urlError, setUrlError] = useState<string | null>(null);
  const [missingFields, setMissingFields] = useState<string[]>([]);

  const [testingEnabled, setTestingEnabled] = useState(provider?.id?.startsWith('http') ?? false);
  const [forceStructuredHttp, setForceStructuredHttp] = useState(false);

  const [rawConfigJson, setRawConfigJson] = useState<string>(
    JSON.stringify(provider?.config || {}, null, 2),
  );

  useEffect(() => {
    if (!provider) {
      setProvider(defaultHttpTarget());
      return;
    }

    const missingFields: string[] = [];

    if (provider?.label || disableNameField) {
      // Label is valid
    } else {
      missingFields.push('Provider Name');
    }

    if (!provider) {
      return;
    }

    if (provider.id?.startsWith('http')) {
      if (provider.config.request) {
        // Skip URL validation for raw request mode
      } else if (!provider.config.url || !validateUrl(provider.config.url)) {
        missingFields.push('URL');
      }
    }

    setMissingFields(missingFields);
  }, [provider]);

  const handleProviderChange = (event: SelectChangeEvent<string>) => {
    setForceStructuredHttp(false);
    const value = event.target.value as string;
    const currentLabel = provider?.label;

    if (value === 'custom') {
      setProvider({
        id: '',
        label: currentLabel,
        config: { temperature: 0.5 },
      });
      setRawConfigJson(JSON.stringify({ temperature: 0.5 }, null, 2));
    } else if (value === 'javascript' || value === 'python') {
      const filePath =
        value === 'javascript'
          ? 'file://path/to/custom_provider.js'
          : 'file://path/to/custom_provider.py';
      setProvider({
        id: filePath,
        config: {},
        label: currentLabel,
      });
    } else if (value === 'http') {
      setProvider({
        ...defaultHttpTarget(),
        label: currentLabel,
      });
    } else if (value === 'websocket') {
      setProvider({
        id: 'websocket',
        label: currentLabel,
        config: {
          type: 'websocket',
          url: 'wss://example.com/ws',
          messageTemplate: '{"message": "{{prompt}}"}',
          transformResponse: 'response.message',
          timeoutMs: 30000,
        },
      });
    } else if (value === 'browser') {
      setProvider({
        id: 'browser',
        label: currentLabel,
        config: {
          steps: [
            {
              action: 'navigate',
              args: { url: 'https://example.com' },
            },
          ],
        },
      });
    } else {
      setProvider({
        id: value,
        config: {},
        label: currentLabel,
      });
    }
  };

  useEffect(() => {
    setTestingEnabled(provider?.id?.startsWith('http') ?? false);
  }, [provider]);

  const updateCustomProvider = (field: string, value: any) => {
    if (typeof provider === 'object') {
      const updatedProvider = { ...provider } as ProviderOptions;

      if (field === 'url') {
        updatedProvider.config.url = value;
        if (validateUrl(value)) {
          setUrlError(null);
        } else {
          setUrlError('Invalid URL format');
        }
      } else if (field === 'method') {
        updatedProvider.config.method = value;
      } else if (field === 'body') {
        updatedProvider.config.body = value;
        const bodyStr = typeof value === 'object' ? JSON.stringify(value) : String(value);
        if (bodyStr.includes('{{prompt}}')) {
          setBodyError(null);
        } else {
          setBodyError('Request body must contain {{prompt}}');
        }
      } else if (field === 'request') {
        try {
          const requestStr = String(value).trim();
          if (requestStr.includes('{{prompt}}')) {
            updatedProvider.config.request = requestStr;
            delete updatedProvider.config.url;
            delete updatedProvider.config.method;
            delete updatedProvider.config.headers;
            delete updatedProvider.config.body;
            setBodyError(null);
          } else {
            setBodyError('Request must contain {{prompt}} template variable');
            return;
          }
        } catch (err) {
          const errorMessage = String(err)
            .replace(/^Error:\s*/, '')
            .replace(/\bat\b.*$/, '')
            .trim();
          setBodyError(`Invalid HTTP request format: ${errorMessage}`);
          return;
        }
      } else if (field === 'label') {
        updatedProvider.label = value;
      } else {
        updatedProvider.config[field] = value;
      }

      setProvider(updatedProvider);
    }
  };

  const updateWebSocketProvider = (field: string, value: any) => {
    if (typeof provider === 'object') {
      const updatedProvider = { ...provider } as ProviderOptions;
      if (field === 'id') {
        updatedProvider.id = value;
        if (validateUrl(value, 'websocket')) {
          setUrlError(null);
        } else {
          setUrlError('Please enter a valid WebSocket URL (ws:// or wss://)');
        }
      } else if (field in updatedProvider.config) {
        (updatedProvider.config as any)[field] = value;
      }
      setProvider(updatedProvider);
    }
  };

  const handleTestProvider = async () => {
    setTestingProvider(true);
    setTestResult(null);

    if (!provider) {
      return;
    }

    try {
      const response = await testProvider(provider, specialProviderType);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = (await response.json()) as {
        test_result: any;
        provider_response: any;
        unalignedProviderResult: any;
        redteamProviderResult: any;
        testHttpStatus: number | undefined;
      };
      const result = data?.test_result;

      if (result?.error) {
        setTestResult({
          success: false,
          message: result.error,
          providerResponse: data.provider_response,
          unalignedProviderResult: data.unalignedProviderResult,
          redteamProviderResult: data.redteamProviderResult,
        });
      } else if (result?.changes_needed) {
        setTestResult({
          success: false,
          message: result.changes_needed_reason,
          suggestions: result.changes_needed_suggestions,
          providerResponse: data.provider_response,
          unalignedProviderResult: data.unalignedProviderResult,
          redteamProviderResult: data.redteamProviderResult,
        });
      } else {
        setTestResult({
          message: 'Provider configuration is valid!',
          providerResponse: data.provider_response,
          unalignedProviderResult: data.unalignedProviderResult,
          redteamProviderResult: data.redteamProviderResult,
        });
        setHasTestedProvider(true);
      }
    } catch (error) {
      console.error('Error testing provider:', error);
      setTestResult({
        success: false,
        message: `An error occurred while testing the provider. ${error}`,
      });
    } finally {
      setTestingProvider(false);
    }
  };

  return (
    <Stack direction="column" spacing={3}>
      {!disableTitle && (
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
          Select Red Team Provider
        </Typography>
      )}

      <Typography variant="body1">
        {description ||
          'A provider is the specific LLM or endpoint you want to evaluate in your red teaming process. In Promptfoo providers are also known as providers. You can configure additional providers later.'}
      </Typography>
      <Typography variant="body1">
        For more information on available providers and how to configure them, please visit our{' '}
        <Link href="https://www.promptfoo.dev/docs/providers/" target="_blank" rel="noopener">
          provider documentation
        </Link>
        .
      </Typography>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'medium', mb: 3 }}>
          Select a Provider
        </Typography>
        {!disableNameField && (
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            label="Provider Name"
            value={provider?.label ?? ''}
            placeholder="e.g. 'customer-service-agent'"
            onChange={(e) => updateCustomProvider('label', e.target.value)}
            margin="normal"
            required
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}

        <Typography variant="body2" color="text.secondary" sx={{ mb: 5 }}>
          The provider name will be used to report vulnerabilities. Make sure it's meaningful and
          re-use it when generating new redteam configs for the same provider. Eg:
          'customer-service-agent', 'compliance-bot'
        </Typography>

        <FormControl fullWidth>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
            <Box sx={{ flex: 1 }}>
              <InputLabel id="predefined-provider-label">Provider Type</InputLabel>
              <Select
                labelId="predefined-provider-label"
                value={provider?.id ?? defaultProviderId ?? ''}
                onChange={handleProviderChange}
                label="Provider Type"
                fullWidth
              >
                {selectOptions.map((provider) => (
                  <MenuItem key={provider.value} value={provider.value}>
                    {provider.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </FormControl>
        {(provider?.id?.startsWith('javascript') || provider?.id?.startsWith('python')) && (
          <TextField
            fullWidth
            label="Custom Provider"
            value={provider?.id ?? ''}
            onChange={(e) => updateCustomProvider('id', e.target.value)}
            margin="normal"
          />
        )}
        {provider?.id?.startsWith('file://') && (
          <>
            {provider?.id?.endsWith('.js') && (
              <Typography variant="body1" sx={{ mt: 1 }}>
                Learn how to set up a custom JavaScript provider{' '}
                <Link
                  href="https://www.promptfoo.dev/docs/providers/custom-api/"
                  target="_blank"
                  rel="noopener"
                >
                  here
                </Link>
                .
              </Typography>
            )}
            {provider?.id?.endsWith('.py') && (
              <Typography variant="body1" sx={{ mt: 1 }}>
                Learn how to set up a custom Python provider{' '}
                <Link
                  href="https://www.promptfoo.dev/docs/providers/python/"
                  target="_blank"
                  rel="noopener"
                >
                  here
                </Link>
                .
              </Typography>
            )}
          </>
        )}
        {provider &&
          (provider?.id === 'custom' || !knownProviderIds.includes(provider?.id ?? '')) && (
            <CustomProviderConfiguration
              selectedProvider={provider}
              updateCustomProvider={updateCustomProvider}
              rawConfigJson={rawConfigJson}
              setRawConfigJson={setRawConfigJson}
              bodyError={bodyError}
            />
          )}

        {provider?.id?.startsWith('http') && (
          <HttpEndpointConfiguration
            selectedTarget={provider}
            updateCustomTarget={updateCustomProvider}
            bodyError={bodyError}
            setBodyError={setBodyError}
            urlError={urlError}
            setUrlError={setUrlError}
            forceStructured={forceStructuredHttp}
            setForceStructured={setForceStructuredHttp}
            updateFullTarget={setProvider}
          />
        )}

        {provider?.id?.startsWith('websocket') && (
          <WebSocketEndpointConfiguration
            selectedTarget={provider}
            updateWebSocketTarget={updateWebSocketProvider}
            urlError={urlError}
          />
        )}

        {provider?.id?.startsWith('browser') && (
          <BrowserAutomationConfiguration
            selectedTarget={provider}
            updateCustomTarget={updateCustomProvider}
          />
        )}
      </Box>

      {testingEnabled && provider && (
        <TestTargetConfiguration
          testingTarget={testingProvider}
          handleTestTarget={handleTestProvider}
          selectedTarget={provider}
          testResult={testResult}
          requiresTransformResponse={requiresTransformResponse}
          updateCustomTarget={updateCustomProvider}
          hasTestedTarget={hasTestedProvider}
          defaultRequestTransform={defaultRequestTransform}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 4,
          width: '100%',
          position: 'relative',
        }}
      >
        {missingFields.length > 0 && (
          <Alert
            severity="error"
            sx={{
              flexGrow: 1,
              mr: 2,
              '& .MuiAlert-message': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <Typography variant="body2">
              Missing required fields: {missingFields.join(', ')}
            </Typography>
          </Alert>
        )}
        {bodyError && (
          <Alert severity="error" sx={{ flexGrow: 1, mr: 2 }}>
            <Typography variant="body2">
              There was an error with the request body: {bodyError}
            </Typography>
          </Alert>
        )}
        <Button
          variant="outlined"
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={onBack}
          sx={{ px: 4, py: 1 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={onActionButtonClick}
          endIcon={<KeyboardArrowRightIcon />}
          disabled={missingFields.length > 0 || bodyError !== null}
          sx={{
            backgroundColor: theme.palette.primary.main,
            '&:hover': { backgroundColor: theme.palette.primary.dark },
            '&:disabled': { backgroundColor: theme.palette.action.disabledBackground },
            px: 4,
            py: 1,
          }}
        >
          {actionButtonText}
        </Button>
      </Box>
    </Stack>
  );
}
