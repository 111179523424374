import { TIME_RANGES } from '../types';
import type { TimeRange } from '../types';

/**
 * Generates an array of UTC timestamps for the given time range
 */
export function generateTimePoints(timeRange: TimeRange): string[] {
  return Array.from({ length: timeRange === '1h' ? 60 : TIME_RANGES[timeRange].hours }, (_, i) => {
    // Start from current UTC time and round down to the appropriate interval
    const now = new Date();
    const utcNow = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
      ),
    );
    // Round down to the nearest minute for 1h, nearest hour for others
    if (timeRange === '1h') {
      utcNow.setUTCSeconds(0);
      // Subtract minutes to get the start time
      const timestamp = utcNow.getTime() - (59 - i) * 60 * 1000;
      return new Date(timestamp).toISOString().replace('.000', '');
    } else {
      utcNow.setUTCMinutes(0, 0);
      // Subtract hours to get the start time
      const timestamp = utcNow.getTime() - (TIME_RANGES[timeRange].hours - 1 - i) * 60 * 60 * 1000;
      return new Date(timestamp).toISOString().replace('.000', '');
    }
  });
}

/**
 * Formats a timestamp for the X-axis based on the time range
 */
export function formatXAxisTick(value: string, timeRange: TimeRange): string {
  const date = new Date(value);
  if (timeRange === '1h') {
    return date.toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
  }
  return timeRange === '24h'
    ? date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
    : date.toLocaleDateString([], { month: 'short', day: 'numeric' });
}

/**
 * Returns the interval for X-axis ticks based on the time range
 */
export function getXAxisInterval(timeRange: TimeRange): number | 'preserveStartEnd' {
  return timeRange === '1h' ? 14 : timeRange === '24h' ? 3 : 'preserveStartEnd';
}

/**
 * Formats a timestamp for tooltips with full date and time
 */
export function formatTooltipLabel(value: string): string {
  const date = new Date(value);
  return date.toLocaleString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    month: 'short',
    day: 'numeric',
  });
}
