import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, type ReactNode } from 'react';
import { callApi } from '@cloud-ui/utils/api';
import type { ConfigDTO } from '@shared/dto';

const ConfigContext = createContext<ConfigDTO | null>(null);

async function fetchConfig(): Promise<ConfigDTO> {
  const response = await callApi('/config');
  if (!response.ok) {
    throw new Error('Failed to load config');
  }
  return response.json();
}

export function ConfigProvider({ children }: { children: ReactNode }) {
  const { data: config, error } = useQuery({
    queryKey: ['config'],
    queryFn: fetchConfig,
    staleTime: Infinity, // Config won't change during session
    retry: 2,
  });

  if (error) {
    console.error('Failed to load application configuration', error);
    return <div>Failed to load application configuration</div>;
  }

  return <ConfigContext.Provider value={config ?? {}}>{children}</ConfigContext.Provider>;
}

export function useConfig() {
  const config = useContext(ConfigContext);
  if (!config) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return config;
}
