import { Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import type { TimeRange } from '../types';
import {
  formatTooltipLabel,
  formatXAxisTick,
  generateTimePoints,
  getXAxisInterval,
} from '../utils/time';

interface TimeSeriesChartProps {
  data: Array<{
    hour: string;
    total: number;
    flagged: number;
  }>;
  timeRange: TimeRange;
}

export default function TimeSeriesChart({ data, timeRange }: TimeSeriesChartProps) {
  const theme = useTheme();

  // Generate array of all points in the time range in UTC
  const allPoints = generateTimePoints(timeRange);

  // Create a map of existing data points
  const dataMap = new Map(data.map((point) => [point.hour, point]));

  // Fill in missing data points with zeros
  const filledData = allPoints.map((hour) => {
    const total = Number(dataMap.get(hour)?.total || 0);
    const flagged = Number(dataMap.get(hour)?.flagged || 0);
    return {
      hour,
      safe: total - flagged,
      flagged,
    };
  });

  return (
    <Paper sx={{ p: 2, height: 400 }}>
      <Typography variant="h6" gutterBottom>
        Requests Over Time
      </Typography>
      <ResponsiveContainer width="100%" height="90%">
        <AreaChart data={filledData} stackOffset="none">
          <defs>
            <linearGradient id="safeGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={theme.palette.success.main} stopOpacity={0.8} />
              <stop offset="95%" stopColor={theme.palette.success.main} stopOpacity={0} />
            </linearGradient>
            <linearGradient id="flaggedGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={theme.palette.error.main} stopOpacity={0.8} />
              <stop offset="95%" stopColor={theme.palette.error.main} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="hour"
            tickFormatter={(value) => formatXAxisTick(value, timeRange)}
            interval={getXAxisInterval(timeRange)}
          />
          <YAxis allowDecimals={false} />
          <Tooltip
            labelFormatter={formatTooltipLabel}
            formatter={(value: number, name: string) => [value, name]}
          />
          <Legend />
          <Area
            type="monotone"
            dataKey="safe"
            stackId="1"
            stroke={theme.palette.success.main}
            fill="url(#safeGradient)"
            name="Safe Requests"
          />
          <Area
            type="monotone"
            dataKey="flagged"
            stackId="1"
            stroke={theme.palette.error.main}
            fill="url(#flaggedGradient)"
            name="Flagged Requests"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Paper>
  );
}
