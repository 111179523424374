import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import microsoftLogo from '@cloud-ui/assets/msft_logo.svg';
import { AuthContext } from '@cloud-ui/contexts/AuthContext';
import { useConfig } from '@cloud-ui/contexts/ConfigContext';
import { API_BASE_URL, callApi } from '@cloud-ui/utils/api';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import WarningIcon from '@mui/icons-material/Warning';
import { Paper, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { AuthMethod } from '@shared/dto/serverSettings';

export default function Login() {
  const config = useConfig();
  const isDangerousPublic = config?.authMethods === undefined || config?.authMethods.length === 0;
  const passwordAuthEnabled = config?.authMethods?.includes(AuthMethod.password);
  const azureAdAuthEnabled = config?.authMethods?.includes(AuthMethod.azureAdOidc);
  const emailAuthEnabled = config?.authMethods?.includes(AuthMethod.email);

  const theme = useTheme();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [loginMethod, setLoginMethod] = React.useState<'magic-link' | 'password'>(
    passwordAuthEnabled && emailAuthEnabled
      ? 'magic-link'
      : passwordAuthEnabled
        ? 'password'
        : 'magic-link',
  );
  const [error, setError] = React.useState('');
  const [emailSent, setEmailSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { user, login } = useContext(AuthContext) || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [navigate, user]);

  if (config?.authMethods?.includes(AuthMethod.iap)) {
    return (
      <div>
        You're in Identity Aware Proxy mode and something is misconfigured or you got here by
        accident. Good luck!
      </div>
    );
  }
  const handleAzureLogin = () => {
    try {
      window.location.href = `${API_BASE_URL}/auth/azure`;
    } catch (err) {
      console.error('Error during Azure login:', err);
      setError('An unexpected error occurred');
    }
  };

  const handleSignIn = async (event: React.FormEvent) => {
    setLoading(true);
    setError('');
    event.preventDefault();

    try {
      // Use password endpoint if password is provided or if password is the only auth method
      const usePasswordAuth = password || (passwordAuthEnabled && !emailAuthEnabled);
      const endpoint = usePasswordAuth ? '/users/login/password' : '/users/login';
      const body = usePasswordAuth ? { email, password } : { email };

      const response = await callApi(endpoint, {
        method: 'POST',
        body: JSON.stringify(body),
      });
      const data = (await response.json()) as { token?: string; message?: string; error?: string };

      if (response.ok) {
        if (data.token) {
          login?.(data.token);
          window.location.href = '/';
        } else {
          setEmailSent(true);
        }
      } else {
        setError(data.error || data.message || 'An error occurred during sign in');
      }
    } catch (err) {
      let errorMessage = 'An unexpected error occurred';
      if (err instanceof Error && err.message.includes('Invalid email or password')) {
        errorMessage = 'Invalid email or password';
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          sx={{
            mb: 4,
            fontWeight: 500,
            color: theme.palette.primary.main,
          }}
        >
          Welcome
        </Typography>

        {(emailAuthEnabled || passwordAuthEnabled || isDangerousPublic) && (
          <>
            {isDangerousPublic && (
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  p: 2,
                  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#2D1600' : '#FFF3E0'),
                  borderRadius: 1,
                  border: '1px solid',
                  borderColor: '#FF6D00',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <WarningIcon sx={{ color: '#FF6D00' }} />
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => (theme.palette.mode === 'dark' ? '#FFE0B2' : '#212121'),
                    fontWeight: 500,
                  }}
                >
                  This instance is running in public mode. Any email address can be used to create a
                  new account.
                </Typography>
              </Box>
            )}
            {emailSent ? (
              <Box mt={2}>
                <Typography>
                  Email sent to {email}. Check your inbox and click the link to login.
                </Typography>
              </Box>
            ) : (
              <Box component="form" onSubmit={handleSignIn} mt={2}>
                <TextField
                  disabled={loading}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                  onInput={(e) => setEmail((e.target as HTMLInputElement).value)}
                  value={email}
                  error={Boolean(error)}
                  sx={{ mb: 2 }}
                />
                {(loginMethod === 'password' || (passwordAuthEnabled && !emailAuthEnabled)) && (
                  <TextField
                    disabled={loading}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={Boolean(error)}
                    sx={{ mb: 2 }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                )}

                {error && (
                  <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                    {error}
                  </Typography>
                )}

                {emailAuthEnabled && passwordAuthEnabled && !isDangerousPublic && (
                  <ToggleButtonGroup
                    value={loginMethod}
                    exclusive
                    onChange={(_, value) => value && setLoginMethod(value)}
                    aria-label="login method"
                    fullWidth
                    sx={{
                      mb: 2,
                      '& .MuiToggleButton-root': {
                        flex: 1,
                        whiteSpace: 'nowrap',
                        gap: 1,
                      },
                    }}
                  >
                    <ToggleButton value="magic-link">
                      <EmailIcon fontSize="small" />
                      Use Email
                    </ToggleButton>
                    <ToggleButton value="password">
                      <KeyIcon fontSize="small" />
                      Password
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
                <Button
                  type="submit"
                  disabled={
                    loading ||
                    !isValidEmail(email) ||
                    ((loginMethod === 'password' || (passwordAuthEnabled && !emailAuthEnabled)) &&
                      !password)
                  }
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mb: 2 }}
                >
                  {'Login'}
                </Button>
                {(loginMethod === 'password' || (passwordAuthEnabled && !emailAuthEnabled)) && (
                  <Box
                    sx={{
                      width: '100%',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 1,
                      mb: 2,
                    }}
                  >
                    <Typography variant="body2">Forgot your password?</Typography>
                    <Button
                      href="/reset-password"
                      color="primary"
                      sx={{
                        p: 0,
                        textTransform: 'none',
                        minWidth: 'auto',
                      }}
                    >
                      Reset it here
                    </Button>
                  </Box>
                )}
                {azureAdAuthEnabled && (
                  <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAzureLogin}
                      size="large"
                      sx={{
                        py: 1.5,
                        px: 4,
                        textTransform: 'none',
                        fontSize: '1.1rem',
                        boxShadow: 2,
                        '&:hover': {
                          boxShadow: 4,
                        },
                        backgroundColor: theme.palette.mode === 'dark' ? '#FFFFFF' : '#2f2f2f',
                        fontColor: theme.palette.mode === 'dark' ? '#5e5e5e' : '#fffff',
                      }}
                      startIcon={<img src={microsoftLogo} alt="Microsoft" width="21" height="21" />}
                    >
                      Sign in with Microsoft
                    </Button>
                  </Box>
                )}
                <Box
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography variant="body2">Don't have an account yet?</Typography>
                  <Button
                    href="mailto:sales@promptfoo.dev"
                    color="primary"
                    sx={{
                      p: 0,
                      textTransform: 'none',
                      minWidth: 'auto',
                    }}
                  >
                    Contact us
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </Paper>
    </Container>
  );
}
