import React, { useCallback, useMemo } from 'react';
import YamlEditor from '@cloud-ui/components/YamlEditor';
import { useConfig } from '@cloud-ui/contexts/ConfigContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { useStartJob } from '@cloud-ui/hooks/useJobs';
import '@cloud-ui/utils/api/jobs';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import SecurityIcon from '@mui/icons-material/Security';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormControlLabel, Switch } from '@mui/material';
import { IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { strategyDisplayNames } from '@promptfoo/redteam/constants';
import type { RedteamPlugin } from '@promptfoo/redteam/types';
import { useRedTeamConfig } from '../hooks/useRedTeamConfig';
import { generateOrderedYaml, getUnifiedConfig } from '../utils/yamlHelpers';

interface PolicyPlugin {
  id: 'policy';
  config: {
    policy: string;
  };
}

export default function Review({ onSave }: { onSave: () => void }) {
  const { config, updateConfig, configId, updateRunOption, hasUnsavedChanges } = useRedTeamConfig();
  const { enableServerSideJobs } = useConfig();
  const { showToast } = useToast();

  const theme = useTheme();

  const { mutate: runRedTeam } = useStartJob();

  const handleRunRedTeam = async () => {
    await onSave();
    runRedTeam({ config, configId, getUnifiedConfig });
  };

  const [isYamlDialogOpen, setIsYamlDialogOpen] = React.useState(false);
  const yamlContent = useMemo(() => generateOrderedYaml(config), [config]);

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateConfig('description', event.target.value);
  };

  const handleSaveYaml = () => {
    const blob = new Blob([yamlContent], { type: 'text/yaml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'promptfooconfig.yaml';
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleOpenYamlDialog = () => {
    setIsYamlDialogOpen(true);
  };

  const handleCloseYamlDialog = () => {
    setIsYamlDialogOpen(false);
  };

  const getPluginSummary = useCallback((plugin: string | RedteamPlugin) => {
    if (typeof plugin === 'string') {
      return { label: plugin, count: 1 };
    }

    if (plugin.id === 'policy') {
      return { label: 'Custom Policy', count: 1 };
    }

    return { label: plugin.id, count: 1 };
  }, []);

  const pluginSummary = useMemo(() => {
    const summary = new Map<string, number>();

    config.plugins.forEach((plugin) => {
      const { label, count } = getPluginSummary(plugin);
      summary.set(label, (summary.get(label) || 0) + count);
    });

    return Array.from(summary.entries()).sort((a, b) => b[1] - a[1]);
  }, [config.plugins, getPluginSummary]);

  const customPolicies = useMemo(() => {
    return config.plugins.filter(
      (p): p is PolicyPlugin => typeof p === 'object' && p.id === 'policy',
    );
  }, [config.plugins]);

  const intents = useMemo(() => {
    return config.plugins
      .filter(
        (p): p is { id: 'intent'; config: { intent: string | string[] } } =>
          typeof p === 'object' && p.id === 'intent' && p.config?.intent !== undefined,
      )
      .map((p) => p.config.intent)
      .flat()
      .filter((intent): intent is string => typeof intent === 'string' && intent.trim() !== '');
  }, [config.plugins]);

  const getStrategyId = (strategy: string | { id: string }): string => {
    return typeof strategy === 'string' ? strategy : strategy.id;
  };

  const strategySummary = useMemo(() => {
    const summary = new Map<string, number>();

    config.strategies.forEach((strategy) => {
      const id = getStrategyId(strategy);
      const label = strategyDisplayNames[id as keyof typeof strategyDisplayNames] || id;
      summary.set(label, (summary.get(label) || 0) + 1);
    });

    return Array.from(summary.entries()).sort((a, b) => b[1] - a[1]);
  }, [config.strategies]);

  return (
    <Box maxWidth="lg" mx="auto">
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 4 }}>
        Review Your Configuration
      </Typography>

      <TextField
        fullWidth
        label="Configuration Description"
        placeholder="My Red Team Configuration"
        value={config.description}
        onChange={handleDescriptionChange}
        variant="outlined"
        sx={{ mb: 4 }}
      />

      <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
        Configuration Summary
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Plugins ({pluginSummary.length})
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {pluginSummary.map(([label, count]) => (
                <Chip
                  key={label}
                  label={count > 1 ? `${label} (${count})` : label}
                  size="small"
                  sx={{
                    backgroundColor:
                      label === 'Custom Policy' ? theme.palette.primary.main : undefined,
                    color:
                      label === 'Custom Policy' ? theme.palette.primary.contrastText : undefined,
                  }}
                />
              ))}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Strategies ({strategySummary.length})
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {strategySummary.map(([label, count]) => (
                <Chip key={label} label={count > 1 ? `${label} (${count})` : label} size="small" />
              ))}
            </Box>
          </Paper>
        </Grid>

        {customPolicies.length > 0 && (
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Custom Policies ({customPolicies.length})
              </Typography>
              <Stack spacing={1}>
                {customPolicies.map((policy, index) => (
                  <Box
                    key={index}
                    sx={{
                      p: 1.5,
                      borderRadius: 1,
                      bgcolor: theme.palette.action.hover,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {policy.config.policy}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Paper>
          </Grid>
        )}

        {intents.length > 0 && (
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Intents ({intents.length})
              </Typography>
              <Stack spacing={1}>
                {intents.map((intent, index) => (
                  <Box
                    key={index}
                    sx={{
                      p: 1.5,
                      borderRadius: 1,
                      bgcolor: theme.palette.action.hover,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {intent}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Paper>
          </Grid>
        )}

        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Additional Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2">Purpose</Typography>
                <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                  {config.purpose || 'Not specified'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
        Run Your Configuration
      </Typography>

      <Box mb={4}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="run-options-content"
            id="run-options-header"
          >
            <Typography variant="h6">Run Options</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="number"
                label="Number of test cases"
                value={config.numTests}
                onChange={(e) => {
                  updateConfig('numTests', Number(e.target.value));
                }}
                helperText="Number of test cases to generate for each plugin"
                error={Boolean(
                  Number.isNaN(config.numTests) || (config.numTests && config.numTests < 1),
                )}
              />

              <TextField
                fullWidth
                type="number"
                label="Delay between API calls (ms)"
                value={config.runOptions?.delay}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value == '' || (!Number.isNaN(Number(value)) && Number(value) >= 0)) {
                    updateRunOption('delay', value);
                  }
                }}
                InputProps={{
                  endAdornment: <Typography variant="caption">ms</Typography>,
                }}
                helperText="Add a delay between API calls to avoid rate limits"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={config.runOptions?.verbose}
                    onChange={(e) => updateRunOption('verbose', e.target.checked)}
                  />
                }
                label={
                  <Box>
                    <Typography variant="body1">Debug mode</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Show additional debug information in logs
                    </Typography>
                  </Box>
                }
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Paper elevation={2} sx={{ p: 3 }}>
        <Stack direction="column" spacing={4}>
          {enableServerSideJobs ? (
            <>
              <Box>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<SecurityIcon />}
                  onClick={handleRunRedTeam}
                  sx={{
                    py: 2,
                    mb: 2,
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                  }}
                >
                  {`${hasUnsavedChanges ? 'Save & ' : ''} Run Red Team`}
                </Button>
              </Box>
              <Divider />
            </>
          ) : (
            <Box>
              <Typography variant="h6" gutterBottom>
                Run via Command Line
              </Typography>
              {config.id ? (
                <>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {enableServerSideJobs
                      ? 'For larger scans or automated workflows, you can run this configuration using the CLI:'
                      : 'To start your red team scan, run this command in your terminal:'}
                  </Typography>
                  <Paper
                    variant="outlined"
                    sx={{
                      p: 3,
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      fontFamily: 'monospace',
                      fontSize: '1.1rem',
                      position: 'relative',
                      textAlign: 'center',
                      mb: 2,
                    }}
                  >
                    <code style={{ color: 'inherit' }}>promptfoo redteam run -c {config.id}</code>
                    <IconButton
                      size="small"
                      onClick={() => {
                        navigator.clipboard.writeText(`promptfoo redteam run -c ${config.id}`);
                        showToast('Command copied to clipboard', 'success');
                      }}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: 'inherit',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                      }}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Paper>
                </>
              ) : (
                <Box sx={{ py: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Save this configuration to run a scan from your CLI.
                  </Typography>
                  <Button variant="contained" color="primary" onClick={onSave} sx={{ mt: 1 }}>
                    Save Configuration
                  </Button>
                </Box>
              )}
            </Box>
          )}

          <Box>
            <Typography variant="h6" gutterBottom>
              Configuration Files
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              You can use these files to manually inspect your configuration.
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSaveYaml}
                startIcon={<SaveIcon />}
              >
                Download YAML
              </Button>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<VisibilityIcon />}
                onClick={handleOpenYamlDialog}
              >
                View YAML
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Paper>

      <Dialog open={isYamlDialogOpen} onClose={handleCloseYamlDialog} maxWidth="lg" fullWidth>
        <DialogTitle>YAML Configuration</DialogTitle>
        <DialogContent>
          <YamlEditor initialYaml={yamlContent} readOnly />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
