import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import LoggedInPasswordReset from './LoggedInPasswordReset';

export default function page() {
  const { user } = useContext(AuthContext) || {};

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <LoggedInPasswordReset email={user.email} />;
}
