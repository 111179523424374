import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { callApi } from '../../../utils/api';

export default function RequestPasswordReset() {
  const theme = useTheme();
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');
  const [emailSent, setEmailSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    setError('');
    event.preventDefault();

    try {
      const response = await callApi('/users/reset-password/request', {
        method: 'POST',
        body: JSON.stringify({ email }),
      });
      const data = (await response.json()) as { message?: string; error?: string };

      if (response.ok) {
        setEmailSent(true);
      } else {
        setError(data.error || data.message || 'An error occurred while requesting password reset');
      }
    } catch (err) {
      console.error('Error during password reset request:', err);
      setError('An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>

        {emailSent ? (
          <Box mt={2}>
            <Typography>
              If an account exists with {email}, you will receive password reset instructions.
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate('/login')}
              sx={{ mt: 2 }}
            >
              Return to Login
            </Button>
          </Box>
        ) : (
          <Box component="form" onSubmit={handleSubmit} mt={2}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter your email address and we'll send you instructions to reset your password.
            </Typography>

            <TextField
              disabled={loading}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              error={Boolean(error)}
              helperText={error}
              sx={{ mb: 2 }}
            />

            <Button
              type="submit"
              disabled={loading || !isValidEmail(email)}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 2 }}
            >
              Send Reset Instructions
            </Button>

            <Button
              fullWidth
              variant="text"
              onClick={() => navigate('/login')}
              sx={{ textTransform: 'none' }}
            >
              Back to Login
            </Button>
          </Box>
        )}
      </Paper>
    </Container>
  );
}
